import Box from '@mui/material/Box';
import dayjs from 'dayjs';

import { Colors } from '../../theme/colors';
import { decimalCalculations } from '../../utils';
import { APP_LIST_COLUMN_TYPES } from '../AppList/types/list.types';
import {
  APP_COLUMN_TYPES,
  AppTableCellWrapperProps,
} from '../AppTable/types/table.types';

export type ICurrencyCell = {
  type: APP_COLUMN_TYPES.CURRENCY | APP_LIST_COLUMN_TYPES.CURRENCY;
  data: {
    value: string;
    currency: string;
    isCent?: boolean;
    color?: string;
    fontWeight?: number;
    fontSize?: number;
    handleNullAsEmpty?: boolean;
    handleNullAsDash?: boolean;
    ignoreFormatting?: boolean;
  };
};

function CurrencyCell<T extends Record<string, unknown>>({
  renderedCellValue,
}: Readonly<AppTableCellWrapperProps<T>>) {
  const { data } = renderedCellValue as unknown as ICurrencyCell;

  const formattedValueWithDecimals = data.isCent
    ? decimalCalculations(data.value).toDecimal().toMoney()
    : decimalCalculations(data.value).toMoney();

  const customFormatting = (value: string) => {
    const splitValue = value.split(' - ');

    const isDateValid = dayjs(splitValue[1]).isValid();

    const formattedDate = isDateValid
      ? dayjs(splitValue[1]).format('DD-MM-YYYY')
      : splitValue[1];

    return splitValue[0] + ' - ' + formattedDate;
  };

  return (
    <Box>
      {data.handleNullAsEmpty ? null : data.handleNullAsDash ? (
        '-'
      ) : data.ignoreFormatting ? (
        <span>{customFormatting(data.value)}</span>
      ) : (
        <>
          <span style={{ marginRight: 'auto', textAlign: 'left', flexGrow: 1 }}>
            {data.currency?.toUpperCase()}
          </span>
          &nbsp;
          <span
            style={{
              color: data.color ?? Colors.fontColorPrimary,
              fontWeight: data.fontWeight ?? 600,
              fontSize: data.fontSize ?? '0.8rem',
            }}>
            {formattedValueWithDecimals}
          </span>
        </>
      )}
    </Box>
  );
}

export default CurrencyCell;
