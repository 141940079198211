import { RESTAURANT_ROLES } from '../constants';

export const getRoleName = (role?: RESTAURANT_ROLES) => {
  switch (role) {
    case RESTAURANT_ROLES.OWNER:
      return 'Owner';

    case RESTAURANT_ROLES.CASHIER:
      return 'Cashier';

    case RESTAURANT_ROLES.ADMINISTRATOR:
      return 'Administrator';

    case RESTAURANT_ROLES.MANAGER:
      return 'Manager';

    case RESTAURANT_ROLES.WAITER:
      return 'Server';

    case RESTAURANT_ROLES.SUPPORT:
      return 'Support';

    default:
      return '';
  }
};
