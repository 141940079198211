import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';

import { Colors } from '../../../theme/colors';
import AppEmptyTable from '../../AppEmptyTable';

interface EmptyTableProps {
  emptyComponent?: React.ReactNode;
}

function EmptyTable({ emptyComponent }: Readonly<EmptyTableProps>) {
  return (
    <Box
      sx={{
        display: 'flex',
        borderRadius: '8px',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        minHeight: '300px',
        background: Colors.backgroundLight,
      }}>
      {emptyComponent ?? (
        <AppEmptyTable icon={<SearchIcon sx={{ color: Colors.primary }} />} />
      )}
    </Box>
  );
}

export default EmptyTable;
