import Decimal from 'decimal.js';

import { isNumeric } from '.';

export const decimalCalculations = (value?: string | number | null) => {
  const obj = {
    value: new Decimal(String(isNumeric(value) ? value : 0)?.replace(/,/g, '')),
    multiply: (input: string | number) => {
      const decimalInput = new Decimal(
        String(isNumeric(input) ? input : 0)?.replace(/,/g, ''),
      ).toDecimalPlaces(2);
      obj.value = obj.value.mul(decimalInput).toDecimalPlaces(2);
      return obj;
    },
    divide: (input?: string | number) => {
      const decimalInput = new Decimal(
        String(isNumeric(input) ? input : 0)?.replace(/,/g, ''),
      ).toDecimalPlaces(2);
      obj.value = obj.value.div(decimalInput).toDecimalPlaces(2);
      return obj;
    },
    percentage: (input?: string | number, isDecimal = true) => {
      const decimalInput = new Decimal(
        String(isNumeric(input) ? input : 0)?.replace(/,/g, ''),
      ).toDecimalPlaces(2);
      obj.value = obj.value
        .div(isDecimal ? 100 : 10000)
        .mul(decimalInput)
        .toDecimalPlaces(2);
      return obj;
    },
    minus: (input?: string | number) => {
      const decimalInput = new Decimal(
        String(isNumeric(input) ? input : 0)?.replace(/,/g, ''),
      ).toDecimalPlaces(2);
      obj.value = obj.value.minus(decimalInput).toDecimalPlaces(2);
      return obj;
    },
    add: (input?: string | number) => {
      const decimalInput = new Decimal(
        String(isNumeric(input) ? input : 0)?.replace(/,/g, ''),
      ).toDecimalPlaces(2);
      obj.value = obj.value.add(decimalInput).toDecimalPlaces(2);
      return obj;
    },
    toCents: () => {
      obj.value = obj.value.mul(100).toDecimalPlaces(0);
      return obj;
    },
    toDecimal: () => {
      obj.value = obj.value.div(100).toDecimalPlaces(2);
      return obj;
    },
    toNumber: (): number => {
      return obj.value.toNumber();
    },
    toString: (): string => {
      return obj.value.toString();
    },
    toMoney: (currency = '', noThousandSeparator = false): string => {
      const result = obj.value
        .toNumber()
        .toFixed(2)
        .replace(/\B(?=(\d{3})+(?!\d))/g, noThousandSeparator ? '' : ',');
      const prefix = currency ? `${currency} ` : '';
      return `${prefix}${result}`;
    },
    toTwoDecimalString: (): string => {
      return obj.value.toFixed(2);
    },
  };

  obj.value = new Decimal(
    String(isNumeric(value) ? value : 0)?.replace(/,/g, ''),
  ).toDecimalPlaces(2);

  return obj;
};

export type DecimalCalculation = ReturnType<typeof decimalCalculations>;
