import Box from '@mui/material/Box';
import AppButton from '../../../components/AppButton';
import { useAdyenOnboardingLink } from '../../../hooks/useAdyenOnboardingLink';

const OverviewOnboardingLink = () => {
  const { refreshing, getOnboardingLink } = useAdyenOnboardingLink();

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <AppButton
        loading={refreshing}
        disabled={refreshing}
        onClick={getOnboardingLink}
        variant="contained">
        Let's get onboarded
      </AppButton>
    </Box>
  );
};

export default OverviewOnboardingLink;
