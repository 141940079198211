import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';

import { Colors } from '../../../theme/colors';

type Props = {
  disabled: boolean;
  onClick: () => void;
};

const ColumnVisibilityButton = ({ disabled, onClick }: Props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        ml: 1,
      }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          width: 'fit-content',
          marginLeft: '5px',
          border: `0.6px solid ${Colors.borderPrimary}`,
          background: Colors.backgroundPrimary,
          borderRadius: '3px',
          height: '100%',
          maxHeight: '100%',
        }}>
        <Tooltip
          title="Hide/UnHide columns"
          placement="left"
          data-testid="column-unHide-button">
          <Button
            onClick={onClick}
            sx={{
              fontSize: '20px',
              color: Colors.primary,
              textTransform: 'none',
              minWidth: 'fit-content',
              height: '100%',
              background: Colors.backgroundPrimary,
              padding: '0px',
              px: '2px',
              position: 'relative',
              '&:hover': {
                background: Colors.backgroundPrimary,
              },
            }}
            disabled={disabled}>
            <span
              style={{
                position: 'relative',
                zIndex: '1',
                display: 'flex',
                marginLeft: '2px',
                marginRight: '2px',
                borderRadius: '4px',
                backgroundColor: Colors.primaryLight,
                width: '20px',
                height: '20px',
                aspectRatio: 1,
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '25px',
                paddingBottom: '3px',
              }}>
              +
            </span>
          </Button>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default ColumnVisibilityButton;
