import SortingDefaultIcon from '../assets/svgs/default-sort.svg?react';
import SortingAscIcon from '../assets/svgs/sorting-asc.svg?react';
import SortingDescIcon from '../assets/svgs/sorting-desc.svg?react';

interface AppSortingIconProps {
  sortDirection: string | undefined;
  sortColumn: string | undefined;
  currentColumn: string;
}

const AppSortingIcon: React.FC<AppSortingIconProps> = ({
  sortDirection,
  sortColumn,
  currentColumn,
}) => {
  // Only return the sorting icon if the currentColumn matches the sortColumn
  if (currentColumn === sortColumn) {
    switch (sortDirection) {
      case 'asc':
        return <SortingAscIcon />;
      case 'desc':
        return <SortingDescIcon />;
      default:
        return <SortingDefaultIcon />;
    }
  } else {
    // If the currentColumn is not the sortColumn, return the default icon
    return <SortingDefaultIcon />;
  }
};

export default AppSortingIcon;
