import InventoryIcon from '@mui/icons-material/Inventory';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { Colors } from '../../../theme/colors';

const PaymentsEmptyTable = ({ title }: { title: string }) => {
  return (
    <>
      <Box
        sx={{
          background: Colors.primaryLight,
          padding: '5px',
          paddingBottom: '2px',
          borderRadius: '10px',
        }}>
        <InventoryIcon
          sx={{ color: Colors.primary }}
          data-testid="no-orders-icon"
        />
      </Box>

      <Typography sx={{ marginTop: '22px', fontWeight: 500 }}>
        {title}
      </Typography>
    </>
  );
};

export default PaymentsEmptyTable;
