import dayjs from 'dayjs';
import tz from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useMemo } from 'react';

import { useAppSelector } from '../../hooks';
import { selectAuth } from '../../redux/selectors/authSelectors';
import { Colors } from '../../theme/colors';
import { APP_LIST_COLUMN_TYPES } from '../AppList/types/list.types';
import {
  APP_COLUMN_TYPES,
  AppTableCellWrapperProps,
} from '../AppTable/types/table.types';

dayjs.extend(utc);
dayjs.extend(tz);

export type IDateCell = {
  type: APP_COLUMN_TYPES.DATE | APP_LIST_COLUMN_TYPES.DATE;
  data: {
    value: string;
    disableLocalTime?: boolean;
  };
};

function DateCell<T extends Record<string, unknown>>({
  renderedCellValue,
}: AppTableCellWrapperProps<T>) {
  const { data } = renderedCellValue as unknown as IDateCell;

  const isDateValid = dayjs(data.value).isValid();

  const { restaurant } = useAppSelector(selectAuth);

  const timeZone = restaurant?.timeZoneValue ? restaurant?.timeZoneValue : '';

  const formattedDate = useMemo(() => {
    return data.disableLocalTime && timeZone
      ? dayjs(data.value).utcOffset(timeZone).format('DD-MM-YYYY hh:mm A')
      : dayjs(data.value).format('DD-MM-YYYY hh:mm A');
  }, [data.disableLocalTime, data.value, timeZone]);

  return (
    <span>
      <span
        style={{
          color: Colors.fontColorSecondary,
          fontWeight: 400,
        }}>
        {isDateValid ? formattedDate : data.value}
      </span>
    </span>
  );
}

export default DateCell;
