import { useCallback } from 'react';
import { selectAuth } from '../redux/selectors/authSelectors';
import { selectPayments } from '../redux/selectors/paymentsSelectors';
import { useAppDispatch } from './useAppDispatch';
import { useAppSelector } from './useAppSelector';
import { fetchAdyenOnboardingLink } from '../features/payments/redux/paymentsSlice';

export const useAdyenOnboardingLink = () => {
  const dispatch = useAppDispatch();
  const { refreshing } = useAppSelector(selectPayments);
  const { restaurant } = useAppSelector(selectAuth);

  const getOnboardingLink = useCallback(async () => {
    if (!restaurant?.adyenAccountId) {
      return;
    }

    const response = await dispatch(fetchAdyenOnboardingLink());

    if (
      response.meta.requestStatus === 'fulfilled' &&
      typeof response.payload !== 'string' &&
      response.payload?.url
    ) {
      window.open(response.payload.url, '_blank');
    }
  }, [dispatch, restaurant?.adyenAccountId]);

  return {
    getOnboardingLink,
    refreshing,
  };
};
