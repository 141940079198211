import { Colors } from '../../theme/colors';
import { APP_LIST_COLUMN_TYPES } from '../AppList/types/list.types';
import {
  APP_COLUMN_TYPES,
  AppTableCellWrapperProps,
} from '../AppTable/types/table.types';

export type ITextCell = {
  type: APP_COLUMN_TYPES.TEXT | APP_LIST_COLUMN_TYPES.TEXT;
  data: {
    value: string | null | number;
    fontWeight?: number;
    color?: string;
    icons?: string[];
    icon?: string;
    iconAlt?: string;
    iconWidth?: number;
    iconHeight?: number;
    customStyle?: React.CSSProperties;
  };
};

function TextCell<T extends Record<string, unknown>>({
  renderedCellValue,
}: AppTableCellWrapperProps<T>) {
  const { data } = renderedCellValue as unknown as ITextCell;

  return (
    <span>
      <span
        style={{
          color: data.color ?? Colors.fontColorPrimary,
          fontWeight: data.fontWeight ?? 400,
          textTransform: 'none',
          ...data.customStyle,
        }}>
        {data.icons && data.icons.length > 0
          ? data.icons.map((icon, index) => (
              <img
                key={index}
                style={{
                  width: data.iconWidth ?? '14px',
                  height: data.iconHeight ?? '14px',
                  marginBottom: '-2px',
                  marginRight: '4px',
                  marginLeft: '4px',
                }}
                src={icon}
                alt={data.iconAlt}
              />
            ))
          : data.icon && (
              <img
                style={{
                  width: data.iconWidth ?? '14px',
                  height: data.iconHeight ?? '14px',
                  marginBottom: '-2px',
                  marginRight: '8px',
                }}
                src={data.icon}
                alt={data.iconAlt}
              />
            )}
        {data.value}
      </span>
    </span>
  );
}

export default TextCell;
