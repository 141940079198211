import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import groupBy from 'lodash/groupBy';
import { useEffect, useMemo, useState } from 'react';

import { Colors } from '../theme/colors';
import AppButton from './AppButton';

export interface IColumn {
  accessorKey: string;
  title: string;
  category: string;

  selected: boolean;
  disabled?: boolean;
  exportOnly?: boolean;
}

type Props = {
  open: boolean;
  title: string;
  buttonTitle?: string;
  columns: IColumn[];

  onClose: () => void;
  onSave: (columns: IColumn[]) => void;
};

const AppColumnSelectorPopup = ({
  open,
  title,
  buttonTitle,
  columns,
  onSave,
  onClose,
}: Props) => {
  const [localColumns, setLocalColumns] = useState<IColumn[]>([]);
  const [hiddenColumns, setHiddenColumns] = useState<IColumn[]>([]);

  useEffect(() => {
    const newColumns = columns.filter((el) => {
      return !el.exportOnly;
    });

    const exportOnlyData = columns.filter((el) => {
      return el.exportOnly;
    });

    setLocalColumns(newColumns);
    setHiddenColumns(exportOnlyData);
  }, [columns, open]);

  const groupedColumns = useMemo(
    () => groupBy(localColumns, (column) => column.category),
    [localColumns],
  );

  const categories = Object.keys(groupedColumns);

  const [isAllSelected] = useMemo(
    () => [localColumns.every((col) => col.selected)],
    [localColumns],
  );

  const handleOnAllChange = () => {
    let newColumns;
    if (isAllSelected) {
      newColumns = localColumns.map((col) => ({
        ...col,
        selected: col.disabled ? col.selected : false,
      }));
    } else {
      newColumns = localColumns.map((col) => ({
        ...col,
        selected: true,
      }));
    }

    setLocalColumns(newColumns);
  };

  const handleOnChange = (column: IColumn) => {
    const newColumns = localColumns.map((col) =>
      col.accessorKey === column.accessorKey
        ? { ...col, selected: !col.selected }
        : col,
    );

    setLocalColumns(newColumns);
  };

  const handleOnSave = () => {
    const columnData = localColumns.concat(hiddenColumns);

    onSave(columnData);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: 2,
          maxWidth: '800px',
          width: 'unset',
        },
      }}>
      <DialogTitle
        id="alert-dialog-title"
        sx={{
          borderBottom: `2px solid ${Colors.borderPrimary}`,
        }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: 30,
          }}>
          <Typography variant="h6" component="div">
            {title}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              color: Colors.fontColorPrimary,
            }}>
            <CloseOutlinedIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent
        sx={{
          padding: 0,
        }}>
        <Box
          sx={{
            m: 3,
            p: 3,
            border: `1px solid ${Colors.borderPrimary}`,
            borderRadius: '8px',
          }}>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="space-around"
            alignItems="flex-start">
            {categories.map((category) => (
              <Box key={category}>
                <Typography
                  variant="body1"
                  gutterBottom
                  sx={{
                    marginLeft: 1,
                    fontWeight: 500,
                    fontSize: 14,
                    textTransform: 'uppercase',
                  }}>
                  {category}
                </Typography>
                {groupedColumns[category].map((column) => (
                  <Stack
                    spacing={1}
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-start"
                    key={column.accessorKey}>
                    <Checkbox
                      data-testid={`checkbox-${column.accessorKey}`}
                      id={column.accessorKey}
                      checked={column.selected}
                      onChange={() => handleOnChange(column)}
                      disabled={column.disabled}
                      sx={{ width: 2, height: 2, padding: 2 }}
                    />
                    <Typography
                      variant="body1"
                      key={column.accessorKey}
                      sx={{
                        fontWeight: 500,
                        color: Colors.fontColorPrimary,
                        fontSize: 12,
                      }}>
                      {column.title}
                    </Typography>
                  </Stack>
                ))}
              </Box>
            ))}
          </Stack>
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderWidth: 2,
          borderTopStyle: 'solid',
          borderTopColor: Colors.borderPrimary,
          paddingInline: 3,
          paddingBlock: 2,
          height: 70,
        }}>
        <Stack direction="row" alignItems="center" justifyContent="flex-start">
          <Checkbox
            data-testid="checkbox-selectAll"
            checked={isAllSelected}
            onChange={handleOnAllChange}
          />
          <Typography
            data-testid="select-deselect-all-column-text"
            variant="body1"
            sx={{
              color: Colors.fontColorPrimary,
              mr: 2,
            }}>
            {isAllSelected ? 'Deselect all columns' : 'Select all columns'}
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="flex-end">
          <AppButton
            data-testid="secondary-button"
            autoFocus
            variant="contained"
            color="secondary"
            title="Cancel"
            onClick={onClose}
          />

          <AppButton
            data-testid="primary-button"
            autoFocus
            variant="contained"
            color="primary"
            title={buttonTitle ? buttonTitle : 'Save'}
            onClick={handleOnSave}
            sx={{
              ml: 1,
            }}
          />
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default AppColumnSelectorPopup;
