import failedIcon from '../../../assets/failed.png';
import onlineLogo from '../../../assets/globe.png';
import refundedIcon from '../../../assets/refunded.png';
import successIcon from '../../../assets/success.png';
import aliPayLogo from '../../../assets/svgs/ali-pay.svg';
import amexLogo from '../../../assets/svgs/amex2.svg';
import cashLogo from '../../../assets/svgs/cash.svg';
import customPaymentLogo from '../../../assets/svgs/custom-payments.svg';
import grabPayLogo from '../../../assets/svgs/grab-pay.svg';
import masterCardLogo from '../../../assets/svgs/master.svg';
import payNowLogo from '../../../assets/svgs/pay-now.svg';
import unionPayLogo from '../../../assets/svgs/union-pay.svg';
import visaLogo from '../../../assets/svgs/visa.svg';
import weChatPayLogo from '../../../assets/svgs/we-chat-pay.svg';
import terminalLogo from '../../../assets/terminal.png';
import {
  APP_COLUMN_TYPES,
  IAppTableRow,
} from '../../../components/AppTable/types/table.types';
import { Colors } from '../../../theme/colors';
import { IPaymentResponseDTO, IPaymentTable } from '../types/payments.types';

const getPaymentStatusChipData = (
  value: string,
): {
  color?: string;
  backgroundColor?: string;
  icon?: string;
  iconAlt?: string;
} => {
  switch (value) {
    case 'Success':
    case 'Succeeded':
      return {
        icon: successIcon,
        iconAlt: 'success',
        backgroundColor: Colors.chipSuccessBackground,
        color: Colors.chipSuccessText,
      };

    case 'Failed':
      return {
        icon: failedIcon,
        iconAlt: 'failed',
        backgroundColor: Colors.chipFailedBackground,
        color: Colors.chipFailedText,
      };

    case 'Refunded':
    case 'Partial Refund':
      return {
        icon: refundedIcon,
        iconAlt: 'refund',
        backgroundColor: Colors.chipDefaultBackground,
        color: Colors.chipDefaultText,
      };

    default:
      return {
        backgroundColor: Colors.chipDefaultBackground,
        color: Colors.chipDefaultText,
      };
  }
};

const getPaymentMethodImage = (paymentMethod: string) => {
  switch (paymentMethod) {
    case 'Terminal':
      return terminalLogo;

    case 'Online':
      return onlineLogo;

    case 'Cash':
      return cashLogo;

    default:
      return customPaymentLogo;
  }
};

const getCardTypeImage = (cardType: string) => {
  switch (cardType) {
    case 'Visa':
      return visaLogo;
    case 'Master Card':
      return masterCardLogo;
    case 'Union Pay':
      return unionPayLogo;
    case 'AMEX':
      return amexLogo;
    case 'Grab Pay':
      return grabPayLogo;
    case 'Ali Pay':
      return aliPayLogo;
    case 'WeChat Pay':
      return weChatPayLogo;
    case 'Pay Now':
      return payNowLogo;
  }
};

const getCardNetworkIconHeight = (payment: IPaymentResponseDTO) => {
  switch (payment.cardNetwork) {
    case 'Union Pay':
      return 20;
    case 'AMEX':
      return 30;
    case 'Grab Pay':
      return 20;
    case 'Ali Pay':
      return 20;
    case 'WeChat Pay':
      return 20;
    case 'PayNow':
      return 20;
    default:
      return 26;
  }
};

export const getFormattedPayment = (payment: IPaymentResponseDTO) => {
  const row: IAppTableRow<IPaymentTable> = {
    key: payment.paymentId,
    currency: {
      type: APP_COLUMN_TYPES.TEXT,
      data: {
        value: payment.currency,
      },
    },
    amount: {
      type: APP_COLUMN_TYPES.CURRENCY,
      data: {
        value: payment.amount,
        currency: payment.currency,
      },
    },
    status: {
      type: APP_COLUMN_TYPES.CHIP,
      data: {
        value: payment.status,
        ...getPaymentStatusChipData(payment.status),
      },
    },
    paymentId: {
      type: APP_COLUMN_TYPES.TEXT,
      data: {
        value: payment.paymentId,
        color: Colors.fontColorSecondary,
      },
    },
    paymentMethod: {
      type: APP_COLUMN_TYPES.TEXT,
      data: {
        value: payment.paymentMethod,
        icon: getPaymentMethodImage(payment.paymentMethod),
        fontWeight: 500,
      },
    },
    placedAtTime: {
      type: APP_COLUMN_TYPES.DATE,
      data: {
        value: payment.placedAtTime,
        disableLocalTime: true,
      },
    },
    cardNetwork: {
      type: APP_COLUMN_TYPES.TEXT,
      data: {
        value: payment.cardNetwork !== '-' ? '' : '-',
        icon: getCardTypeImage(payment.cardNetwork),
        iconWidth: 40,
        iconHeight: getCardNetworkIconHeight(payment),
      },
    },
    cardType: {
      type: APP_COLUMN_TYPES.TEXT,
      data: {
        value: payment.cardType,
      },
    },
    cardCountry: {
      type: APP_COLUMN_TYPES.TEXT,
      data: {
        value: payment.cardCountry,
      },
    },
    trxFeePercentage: {
      type: APP_COLUMN_TYPES.TEXT,
      data: {
        value: payment.trxFeePercentage,
      },
    },
    trxFixedPercentageValue: {
      type: APP_COLUMN_TYPES.CURRENCY,
      data: {
        value: payment.trxFixedPercentageValue,
        currency: payment.currency,
      },
    },
    trxFixedFeeValue: {
      type: APP_COLUMN_TYPES.CURRENCY,
      data: {
        value: payment.trxFixedFeeValue,
        currency: payment.currency,
      },
    },
    totalTrxFee: {
      type: APP_COLUMN_TYPES.CURRENCY,
      data: {
        value: payment.totalTrxFee,
        currency: payment.currency,
      },
    },
    payable: {
      type: APP_COLUMN_TYPES.CURRENCY,
      data: {
        value: payment.payable,
        currency: payment.currency,
      },
    },
    subRows: [],
    actions: [],
  };

  return row;
};

export const getFormattedPaymentExport = (payment: IPaymentResponseDTO) => {
  const row: IAppTableRow<IPaymentTable> = {
    key: payment.paymentId,
    currency: {
      type: APP_COLUMN_TYPES.TEXT,
      data: {
        value: payment.currency,
      },
    },
    amount: {
      type: APP_COLUMN_TYPES.CURRENCY,
      data: {
        value: payment.amount,
        currency: payment.currency,
      },
    },
    status: {
      type: APP_COLUMN_TYPES.CHIP,
      data: {
        value: payment.status,
        ...getPaymentStatusChipData(payment.status),
      },
    },
    paymentId: {
      type: APP_COLUMN_TYPES.TEXT,
      data: {
        value: payment.paymentId,
        color: Colors.fontColorSecondary,
      },
    },
    paymentMethod: {
      type: APP_COLUMN_TYPES.TEXT,
      data: {
        value: payment.paymentMethod,
        icon: getPaymentMethodImage(payment.paymentMethod),
        fontWeight: 500,
      },
    },
    placedAtTime: {
      type: APP_COLUMN_TYPES.DATE,
      data: {
        value: payment.placedAtTime,
        disableLocalTime: true,
      },
    },
    cardNetwork: {
      type: APP_COLUMN_TYPES.TEXT,
      data: {
        value: payment.cardNetwork !== '-' ? payment.cardNetwork : '-',
        icon: getCardTypeImage(payment.cardNetwork),
        iconWidth: 40,
        iconHeight: getCardNetworkIconHeight(payment),
      },
    },
    cardType: {
      type: APP_COLUMN_TYPES.TEXT,
      data: {
        value: payment.cardType,
      },
    },
    cardCountry: {
      type: APP_COLUMN_TYPES.TEXT,
      data: {
        value: payment.cardCountry,
      },
    },
    trxFeePercentage: {
      type: APP_COLUMN_TYPES.TEXT,
      data: {
        value: payment.trxFeePercentage,
      },
    },
    trxFixedPercentageValue: {
      type: APP_COLUMN_TYPES.CURRENCY,
      data: {
        value: payment.trxFixedPercentageValue,
        currency: payment.currency,
      },
    },
    trxFixedFeeValue: {
      type: APP_COLUMN_TYPES.CURRENCY,
      data: {
        value: payment.trxFixedFeeValue,
        currency: payment.currency,
      },
    },
    totalTrxFee: {
      type: APP_COLUMN_TYPES.CURRENCY,
      data: {
        value: payment.totalTrxFee,
        currency: payment.currency,
      },
    },
    payable: {
      type: APP_COLUMN_TYPES.CURRENCY,
      data: {
        value: payment.payable,
        currency: payment.currency,
      },
    },
    subRows: [],
    actions: [],
  };

  return row;
};
