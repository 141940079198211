import Box from '@mui/material/Box';
import { useCallback, useEffect, useMemo, useState } from 'react';

import AppTable from '../../../components/AppTable';
import { IAppTableColumn } from '../../../components/AppTable/types/table.types';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { selectApp } from '../../../redux/selectors/appSelectors';
import { selectAuth } from '../../../redux/selectors/authSelectors';
import { selectPayments } from '../../../redux/selectors/paymentsSelectors';
import PaymentsEmptyTable from '../components/PaymentsEmptyTable';
import { TABLE_TYPE } from '../constants';
import { fetchPaymentsDetails, setColumns } from '../redux/paymentsSlice';
import { getFormattedPayment } from '../utils/formatting.utils';

const PaymentsView = () => {
  const dispatch = useAppDispatch();

  const { paymentsData, columns, refreshing, activeTab, paymentsCounts } =
    useAppSelector(selectPayments);

  const { restaurant } = useAppSelector(selectAuth);
  const { reportStartDate, reportEndDate } = useAppSelector(selectApp);

  const [pageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [total, setTotal] = useState<number>(0);

  const handlePreviousClick = () => {
    setPageIndex((prevState) => Math.max(prevState - 1, 0));
  };

  const handleNextClick = () => {
    setPageIndex((prevState) => prevState + 1);
  };

  const getData = useCallback(async () => {
    const activeTabString = activeTab.join(',');

    await dispatch(
      fetchPaymentsDetails({
        type: TABLE_TYPE.TABLE,
        status: activeTabString,
        limit: pageSize,
        skip: pageIndex,
        date_from: reportStartDate,
        date_to: reportEndDate,
        offset: restaurant?.timeZoneValue,
      }),
    );
  }, [
    activeTab,
    dispatch,
    pageIndex,
    pageSize,
    reportEndDate,
    reportStartDate,
    restaurant?.timeZoneValue,
  ]);

  useEffect(() => {
    getData();
  }, [dispatch, getData]);

  useEffect(() => {
    setPageIndex(0);
    setTotal(paymentsCounts.total);
  }, [activeTab, paymentsCounts.total]);

  const formattedPaymentsData = useMemo(() => {
    return paymentsData.map(getFormattedPayment);
  }, [paymentsData]);

  const handleOnChangeColumnVisibility = (newColumns: IAppTableColumn[]) => {
    dispatch(setColumns(newColumns));
  };

  return (
    <Box
      sx={{
        boxSizing: 'border-box',
        width: '100%',
      }}
      data-testid="Payments">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          boxSizing: 'border-box',
          borderColor: 'divider',
          gap: '10px',
          width: '100%',
        }}></Box>
      <AppTable
        refreshing={refreshing}
        handleNextClick={handleNextClick}
        handlePreviousClick={handlePreviousClick}
        pagination={{
          total,
          pageIndex,
          pageSize,
        }}
        columns={columns}
        emptyComponent={<PaymentsEmptyTable title={'No records found'} />}
        rows={formattedPaymentsData}
        onChangeColumnVisibility={handleOnChangeColumnVisibility}
      />
    </Box>
  );
};

export default PaymentsView;
