import TransactionsView from '../views/TransactionsView';
import { selectAuth } from '../../../redux/selectors/authSelectors';
import { useAppSelector } from '../../../hooks';
import { openGlobalSnackbar } from '../../../components/AppSnackbar/globalSnackbar';
import { SNACKBARTYPE } from '../../../components/AppSnackbar';

const TransactionsPage = () => {
  const { restaurant } = useAppSelector(selectAuth);

  if (!restaurant) {
    openGlobalSnackbar(
      'Failed to load restaurant data. Please try again later',
      SNACKBARTYPE.ERROR,
    );
    return null;
  }

  return <TransactionsView restaurant={restaurant} />;
};

export default TransactionsPage;
