import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import tz from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import MaterialReactTable, {
  MRT_ColumnDef as MrtColumnDef,
  MRT_Row,
  MRT_VisibilityState,
} from 'material-react-table';
import { ReactNode, useCallback, useMemo, useState } from 'react';

import { useAppSelector } from '../../hooks';
import { selectAuth } from '../../redux/selectors/authSelectors';
import { Colors } from '../../theme/colors';
import ChipCell from '../AppCells/ChipCell';
import CurrencyCell from '../AppCells/CurrencyCell';
import DateCell from '../AppCells/DateCell';
import TextCell from '../AppCells/TextCell';
import AppColumnSelectorPopup, { IColumn } from '../AppColumnSelectorPopup';
import AppSortingIcon from '../AppSortingIcon';
import ColumnVisibilityButton from './components/ColumnVisibilityButton';
import EmptyTable from './components/EmptyTable';
import Pagination from './components/Pagination';
import {
  APP_COLUMN_TYPES,
  AppTableCellWrapperProps,
  AppTableProps,
  IAppTableCell,
  IAppTableRow,
} from './types/table.types';

dayjs.extend(utc);
dayjs.extend(tz);

function AppTable<T extends Record<string, unknown>>({
  columns = [],
  refreshing = false,
  pagination,
  rows = [],
  handlePreviousClick,
  handleNextClick,
  onChangeColumnVisibility,
  emptyComponent,
  hideHeader,
  enableRowActions,
  enablePagination = true,
  enableExpanding,
  enableExpandAll,
  onClickActions,
  onClickRow,
  dragLoading,
  onDragDropChange,
  preview = false,
  lastUpdatedOn,
  onPressSortColumn,
  sortOrder = undefined,
  sortColumn = undefined,
  enableShowAllResultsButton = false,
  handleShowAllResultsClick,
}: Readonly<AppTableProps<T>>) {
  const [isColumnVisibilityPopupOpen, setIsColumnVisibilityPopupOpen] =
    useState(false);

  const { restaurant } = useAppSelector(selectAuth);
  const timeZone = restaurant?.timeZoneValue ? restaurant?.timeZoneValue : '';

  const handleOnChangeColumnVisibility = (newColumns: IColumn[]) => {
    const formattedDisplayColumns = columns.map((originalCol) => {
      const newCol = newColumns.find(
        (col) => col.accessorKey === originalCol.accessorKey,
      );
      return { ...originalCol, visible: !!newCol?.selected };
    });
    onChangeColumnVisibility?.(formattedDisplayColumns);
    setIsColumnVisibilityPopupOpen(false);
  };

  const columnVisibility: MRT_VisibilityState = useMemo(() => {
    const visibility: Record<string, boolean> = {};
    columns.forEach((col) => {
      visibility[col.accessorKey] = col.visible;
    });

    return visibility;
  }, [columns]);

  const renderCell = useCallback(
    (props: AppTableCellWrapperProps<T>): ReactNode => {
      switch ((props.renderedCellValue as unknown as IAppTableCell).type) {
        case APP_COLUMN_TYPES.CURRENCY:
          return <CurrencyCell {...props} />;

        case APP_COLUMN_TYPES.DATE:
          return <DateCell {...props} />;

        case APP_COLUMN_TYPES.CHIP:
          return <ChipCell {...props} />;

        default:
          return <TextCell {...props} />;
      }
    },
    [],
  );

  const columnsData: MrtColumnDef[] = useMemo(() => {
    return columns
      .filter((el) => {
        return !el.exportOnly;
      })
      .map((column, index) => {
        const isSortable = column.enableSorting;

        const mappedColumn: MrtColumnDef = {
          accessorKey: column.accessorKey,
          header: column.header, // Dummy header to satisfy type requirement
          Header: () => (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                cursor: isSortable ? 'pointer' : 'default',
              }}
              onClick={() => {
                if (isSortable) {
                  onPressSortColumn?.(column.accessorKey);
                }
              }}>
              <span>{column.header}</span>
              {isSortable && (
                <AppSortingIcon
                  sortDirection={sortOrder}
                  sortColumn={sortColumn}
                  currentColumn={column.accessorKey}
                />
              )}
            </Box>
          ),
          size: 0,
          muiTableHeadCellProps: {
            align: column.headerAlign,
            sx: {
              py: 1,
              color: Colors.fontColorPrimary,
              fontWeight: 600,
              fontSize: '11px',
              ...(column.headerSx ?? {}),
              ...(index === columns.length - 1 ? { minWidth: 0 } : {}),
            },
          },
          muiTableBodyCellProps: {
            align: column.bodyAlign,
            sx: {
              py: 1,
              color: Colors.fontColorSecondary,
              fontWeight: 500,
              height: '36px',
              ...(column.bodySx ?? {}),
              ...(index === columns.length - 1
                ? { width: 'unset', minWidth: 0 }
                : {}),
            },
          },
          Cell: (props) =>
            renderCell(props as unknown as AppTableCellWrapperProps<T>),
        };
        return mappedColumn;
      });
  }, [columns, renderCell, onPressSortColumn, sortOrder, sortColumn]);

  const formattedColumns: IColumn[] = useMemo(
    () =>
      columns.map((column) => ({
        accessorKey: column.accessorKey,
        category: column.category,
        selected: column.visible,
        title: column.header,
        disabled: !column.visibilityChangeable,
        exportOnly: column.exportOnly,
      })),
    [columns],
  );

  let content;

  if (rows.length === 0 && refreshing) {
    content = <Box sx={{ height: '50vh' }}></Box>;
  } else if (rows.length === 0 && !refreshing) {
    content = <EmptyTable emptyComponent={emptyComponent} />;
  }

  if (preview) {
    const previewRows = preview
      ? (() => {
          const emptyRows: IAppTableRow<T>[] = [];
          for (let i = 0; i < 10; i++) {
            const emptyRow = {} as IAppTableRow<T>;
            emptyRow.key = `{i}`;
            emptyRow.actions = [];
            emptyRow.subRows = [];

            columns.forEach((column) => {
              (emptyRow as Record<string, IAppTableCell>)[column.accessorKey] =
                {
                  type: APP_COLUMN_TYPES.CHIP,
                  data: {
                    value: '',
                    sx: {
                      width: '70px',
                      height: '10px',
                      borderColor: Colors.chipDefaultBackground,
                    },
                  },
                } as IAppTableCell;
            });

            emptyRows.push(emptyRow);
          }
          return emptyRows;
        })()
      : [];

    return (
      <Box>
        <Box
          sx={{
            flexGrow: 1,
            overflow: 'auto',
            border: `1px solid ${Colors.borderPrimary}`,
            borderBottomWidth: 0,
            borderTopWidth: 1,
            borderRightWidth: 0,
            borderLeftWidth: 0,
            opacity: refreshing ? 0.5 : 1,
          }}>
          <MaterialReactTable
            columns={columnsData}
            data={previewRows}
            enableTopToolbar={false}
            enableBottomToolbar={false}
            enableSorting={false}
            enableDensityToggle={false}
            enableColumnActions={false}
            initialState={{ density: 'compact' }}
            muiTablePaperProps={{
              sx: {
                boxShadow: 'none',
              },
            }}
            muiTableBodyRowProps={{ hover: false, sx: { cursor: 'default' } }}
          />
          <Pagination
            start={1}
            end={10}
            rows={previewRows}
            rowCount={500}
            handlePreviousClick={undefined}
            disablePrevious={true}
            handleNextClick={undefined}
            disableNext={true}
            disablePageIndicator={true}
          />
        </Box>
      </Box>
    );
  } else {
    return (
      <Box
        sx={{
          position: 'relative',
        }}>
        <Backdrop
          open={refreshing}
          sx={{
            zIndex: 999,
            position: 'absolute',
            color: 'transparent',
            backgroundColor: 'transparent',
          }}>
          <CircularProgress size="25px" thickness={5} />
        </Backdrop>

        {(rows.length === 0 && refreshing) ||
        (rows.length === 0 && !refreshing) ? (
          content
        ) : (
          <>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                background: Colors.backgroundPrimary,
                opacity: refreshing ? 0.5 : 1,
              }}>
              <Box
                sx={{
                  flexGrow: 1,
                  overflow: 'hidden',
                  border: `1px solid ${Colors.borderPrimary}`,
                  borderRadius: hideHeader ? '8px' : 0,
                  borderBottomWidth: 0,
                  borderRightWidth: hideHeader ? 1 : 0,
                  borderLeftWidth: hideHeader ? 1 : 0,
                  borderTopWidth: 1,
                  maxHeight: 'calc(100VH - 152px)',
                }}>
                <MaterialReactTable
                  enablePinning
                  enableStickyHeader={!hideHeader}
                  enablePagination={enablePagination}
                  enableTableHead={!hideHeader}
                  columns={columnsData}
                  data={rows}
                  enableTopToolbar={false}
                  enableBottomToolbar={false}
                  enableSorting={false}
                  manualSorting={true}
                  enableDensityToggle={false}
                  enableRowActions={enableRowActions}
                  enableExpanding={enableExpanding}
                  enableExpandAll={enableExpandAll}
                  enableRowOrdering={!!onDragDropChange}
                  muiTableBodyRowDragHandleProps={({ table }) => ({
                    onDragEnd: () => {
                      const { draggingRow, hoveredRow } = table.getState();
                      if (draggingRow && hoveredRow) {
                        onDragDropChange?.(
                          (draggingRow.original as IAppTableRow<T>).key,
                          (hoveredRow as MRT_Row<IAppTableRow<T>>).original.key,
                        );
                      }
                    },
                    draggable: !dragLoading,
                    sx: {
                      cursor: dragLoading ? 'wait' : 'grab',
                    },
                  })}
                  positionActionsColumn="last"
                  muiTableHeadCellProps={{
                    sx: {
                      py: 1,
                    },
                  }}
                  localization={{
                    actions: '',
                  }}
                  muiTableBodyRowProps={({ row }) => ({
                    onClick: () => {
                      onClickRow?.(row.original as IAppTableRow<T>);
                    },
                    sx: {
                      cursor: onClickRow ? 'pointer' : 'default',
                      ':active': {
                        opacity: onClickRow ? 0.5 : 1,
                      },
                    },
                  })}
                  renderRowActionMenuItems={({ row, closeMenu }) => {
                    const value = row.original as IAppTableRow<T>;
                    return value.actions.map((action) => (
                      <MenuItem
                        disabled={action.disabled}
                        key={action.key}
                        onClick={() => {
                          onClickActions?.(action.key, value);
                          closeMenu();
                        }}
                        sx={{
                          color: action.fontColor ?? Colors.fontColorPrimary,
                          background:
                            action.backgroundColor ?? Colors.backgroundPrimary,
                          fontWeight: action.fontWeight ?? 500,
                          opacity: action.disabled ? 0.5 : 1,
                        }}>
                        {action.name}
                      </MenuItem>
                    ));
                  }}
                  enableColumnActions={false}
                  initialState={{ density: 'compact' }}
                  state={{
                    columnVisibility,
                    isLoading: rows.length === 0 && refreshing,
                  }}
                  muiLinearProgressProps={{
                    color: 'secondary',
                  }}
                  muiTableProps={{
                    sx: {
                      boxShadow: 'none',
                      border: 'none',
                      width: '100%',
                    },
                  }}
                  muiTableContainerProps={{
                    sx: {
                      minWidth: '100%',
                      flex: '1',
                      maxWidth: '1024px',
                      height: '100%',
                      maxHeight: 'calc(100VH - 152px)',
                      '&::-webkit-scrollbar': {
                        width: '4px',
                        background: Colors.backgroundPrimary,
                        borderRadius: '4px',
                        height: '4px',
                      },
                      '&::-webkit-scrollbar-thumb': {
                        backgroundColor: Colors.greyBorder,
                        borderRadius: '4px',
                      },
                    },
                  }}
                  muiTableBodyProps={{
                    sx: {
                      '& tr:nth-of-type(odd)': {
                        background: Colors.backgroundPrimary,
                      },
                      '& tr:nth-of-type(even)': {
                        background: Colors.backgroundPrimary,
                      },
                    },
                  }}
                />
              </Box>

              {onChangeColumnVisibility ? (
                <ColumnVisibilityButton
                  disabled={columns.length === 0}
                  onClick={() => setIsColumnVisibilityPopupOpen(true)}
                />
              ) : null}
            </Box>

            {pagination ? (
              <Pagination
                start={pagination.pageIndex * pagination.pageSize + 1}
                end={
                  pagination.pageIndex * pagination.pageSize +
                  pagination.pageSize
                }
                rows={rows}
                rowCount={pagination.total}
                handlePreviousClick={handlePreviousClick}
                disablePrevious={
                  pagination.showAllStatus || pagination.pageIndex === 0
                }
                handleNextClick={handleNextClick}
                disableNext={
                  pagination.showAllStatus ||
                  pagination.total <=
                    (pagination.pageIndex + 1) * pagination.pageSize
                }
                enableShowAllResultsButton={enableShowAllResultsButton}
                handleShowAllResultsClick={handleShowAllResultsClick}
                showAllResultsStatus={pagination.showAllStatus}
              />
            ) : null}

            {lastUpdatedOn ? (
              <Typography
                sx={{
                  color: Colors.fontColorSecondary,
                  fontSize: '12px',
                  fontWeight: 500,
                  mt: 1,
                  ml: 1,
                  mb: 3,
                }}>
                {`Last updated on ${dayjs(lastUpdatedOn)
                  .utcOffset(timeZone)
                  .format('DD-MM-YYYY hh:mm A')}`}
              </Typography>
            ) : null}

            <AppColumnSelectorPopup
              open={isColumnVisibilityPopupOpen}
              columns={formattedColumns}
              title="Choose columns to display"
              onClose={() => setIsColumnVisibilityPopupOpen(false)}
              onSave={handleOnChangeColumnVisibility}
            />
          </>
        )}
      </Box>
    );
  }
}

export default AppTable;
