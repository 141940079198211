import { ORDER_TYPE } from '../constants';

export * from './calculations.utils';
export * from './formatting.utils';

/**
 *
 * @param {string} color - hex color code
 * @param {number} opacity - number between 0 and 1 for the opacity
 * @returns
 */
export function getAlphaHex(color: string, opacity: number) {
  return (
    color +
    Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255)
      .toString(16)
      .toUpperCase()
  );
}

export function isNumeric(n: unknown) {
  return !isNaN(parseFloat(n as string)) && isFinite(n as number);
}

export const checkMinImageDimensions = async (
  file: File,
  dimensions: {
    width: number;
    height: number;
  },
) => {
  // first check if the file is an image
  if (!file.type.startsWith('image/')) {
    return false;
  }
  const img = new Image();
  img.src = URL.createObjectURL(file);
  await img.decode();
  const width = img.width;
  const height = img.height;

  return width >= dimensions.width && height >= dimensions.height;
};

export const getOrderType = (type?: ORDER_TYPE) => {
  switch (type) {
    case ORDER_TYPE.DELIVERY:
      return 'Delivery';

    case ORDER_TYPE.PICKUP:
      return 'Takeaway';

    default:
      return 'Dine-in';
  }
};

export const handleImageProcessing = (imageFile: File) => {
  const reader = new FileReader();
  return new Promise<string>((resolve, reject) => {
    reader.onload = (event) => {
      const invertedBase64 = invertAndConvertToBase64(
        event.target?.result as string,
      );
      resolve(invertedBase64);
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsDataURL(imageFile);
  });
};

const invertAndConvertToBase64 = (imageDataURL: string) => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  return new Promise<string>((resolve, reject) => {
    const img = new Image();

    img.onload = () => {
      // Set canvas dimensions to match the image dimensions
      canvas.width = img.width;
      canvas.height = img.height;

      // Draw the image onto the canvas
      ctx?.drawImage(img, 0, 0);

      // Get the image data from the canvas
      const imageData = ctx?.getImageData(0, 0, canvas.width, canvas.height);

      if (!imageData) {
        reject(Error('Failed to get image data.'));
        return;
      }

      // Invert the colors
      invertColors(imageData.data);

      // Put the modified image data back onto the canvas
      ctx?.putImageData(imageData, 0, 0);

      // Convert the canvas content to a base64 string
      const invertedBase64 = canvas.toDataURL().split(',')[1]; // Extract base64 content;
      resolve(invertedBase64);
    };

    img.onerror = (error) => {
      reject(error);
    };

    img.src = imageDataURL;
  });
};

const invertColors = (data: Uint8ClampedArray) => {
  // Invert each pixel color
  for (let i = 0; i < data.length; i += 4) {
    // Invert red, green, and blue channels
    data[i] = 255 - data[i];
    data[i + 1] = 255 - data[i + 1];
    data[i + 2] = 255 - data[i + 2];
    // Keep the alpha channel unchanged
  }
};
