import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import { TransactionsOverview } from '@adyen/adyen-platform-experience-web';
import '@adyen/adyen-platform-experience-web/adyen-platform-experience-web.css';
import { useAdyenPlatformCore } from '../../../hooks/useAdyenPlatformCore';
import { useAppSelector } from '../../../hooks';
import { selectAdyenSessions } from '../../../redux/selectors/adyenSessionSelectors';
import { RestaurantResponseDTO } from '../../auth/types/auth.types';

const id = 'transactions-overview-container';

type Props = {
  restaurant: RestaurantResponseDTO;
};

const TransactionsView = ({ restaurant }: Props) => {
  const { isLoadingPlatformSession } = useAppSelector(selectAdyenSessions);

  useAdyenPlatformCore({
    Component: TransactionsOverview,
    id,
    resId: restaurant.id,
  });

  return (
    <>
      <Box
        id={id}
        sx={{
          position: 'relative',
          width: '100%',
        }}
      />
      <Backdrop
        open={isLoadingPlatformSession}
        sx={{
          position: 'absolute',
          zIndex: 999,
          backgroundColor: 'transparent',
        }}>
        <CircularProgress size="25px" thickness={5} />
      </Backdrop>
    </>
  );
};

export default TransactionsView;
