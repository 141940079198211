import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import { useEffect } from 'react';
import { useAppDispatch } from '../../../hooks';
import { getRestaurant } from '../../auth/redux/authSlice';
import OverviewStatusMessage from '../components/OverviewStatusMessage';

const OverviewPage = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const handleFocus = async () => {
      await dispatch(getRestaurant());
    };

    window.addEventListener('focus', handleFocus);
    return () => {
      window.removeEventListener('focus', handleFocus);
    };
  }, [dispatch]);

  return (
    <Box
      sx={{
        pt: 2,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '85vh',
      }}>
      <Card
        variant="outlined"
        sx={{
          width: '100%',
          maxWidth: '800px',
          overflowY: 'auto',
          p: 3,
        }}>
        <OverviewStatusMessage />
      </Card>
    </Box>
  );
};

export default OverviewPage;
