import Box from '@mui/material/Box';

import { Colors } from '../../theme/colors';
import { getAlphaHex } from '../../utils';
import { APP_LIST_COLUMN_TYPES } from '../AppList/types/list.types';
import {
  APP_COLUMN_TYPES,
  AppTableCellWrapperProps,
} from '../AppTable/types/table.types';

export type IChipCell = {
  type: APP_COLUMN_TYPES.CHIP | APP_LIST_COLUMN_TYPES.CHIP;
  data: {
    value: string;
    color?: string;
    backgroundColor?: string;
    icon?: string;
    iconAlt?: string;
    prefix?: string;
    suffix?: string;
    sx?: React.CSSProperties;
  };
};

function ChipCell<T extends Record<string, unknown>>({
  renderedCellValue,
}: Readonly<AppTableCellWrapperProps<T>>) {
  const { data } = renderedCellValue as unknown as IChipCell;

  return (
    <Box>
      {data.prefix}
      {data.prefix ? ' - ' : ''}
      <Box
        sx={{
          borderRadius: '5px',
          paddingLeft: '8px',
          paddingRight: '8px',
          display: 'inline-block',
          textAlign: 'center',
          textWrap: 'nowrap',
          backgroundColor: data.backgroundColor ?? Colors.chipDefaultBackground,
          borderWidth: 1,
          borderColor: getAlphaHex(data.color ?? Colors.chipDefaultText, 0.2),
          borderStyle: 'solid',
          ...data.sx,
        }}>
        {data.icon ? (
          <img
            style={{
              width: '14px',
              height: '14px',
              marginBottom: '-2px',
              marginRight: '8px',
            }}
            src={data.icon}
            alt={data.iconAlt}
          />
        ) : null}
        <span
          style={{
            color: data.color ?? Colors.chipDefaultText,
            fontWeight: 500,
          }}>
          {data.value}
        </span>
      </Box>
      {data.suffix ? ' - ' : ''}
      {data.suffix}
    </Box>
  );
}

export default ChipCell;
