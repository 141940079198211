import {
  AdyenPlatformExperience,
  Core,
  onErrorHandler,
  PayoutsOverview,
  TransactionsOverview,
} from '@adyen/adyen-platform-experience-web';
import { useCallback, useEffect, useState } from 'react';
import { useAppDispatch } from './useAppDispatch';
import { SentryCaptureError } from '../config/sentry-setup';
import { fetchPlatformSession } from '../features/auth/redux/adyenSessionSlice';
import { AdyenSessionResponseDTO } from '../types/features/adyen.types';
import Environment from '../config/environment';
import { ENVIRONMENTS } from '../constants';
import { openGlobalSnackbar } from '../components/AppSnackbar/globalSnackbar';
import { SNACKBARTYPE } from '../components/AppSnackbar';

export interface AdyenLocaleState {
  'en-US': {
    [key: string]: string;
  };
}

export interface AdyenTranslationsState {
  translations?: {
    [key: string]: string;
  };
}

type useAdyenPlatformCoreProps = {
  Component: typeof PayoutsOverview | typeof TransactionsOverview;
  id: string;
  resId?: number;
};

export const useAdyenPlatformCore = ({
  Component,
  id,
  resId,
}: useAdyenPlatformCoreProps) => {
  const dispatch = useAppDispatch();
  const [loadedResId, setLoadedResId] = useState<number>();
  const [core, setCore] =
    useState<Core<[], AdyenLocaleState & AdyenTranslationsState>>();

  const handleOnSessionCreate = useCallback(async () => {
    const response = await dispatch(fetchPlatformSession());

    if (response.meta.requestStatus === 'fulfilled') {
      const responsePayload = response.payload as AdyenSessionResponseDTO;
      return {
        id: responsePayload.id,
        token: responsePayload.token,
      };
    }

    return { id: '', token: '' };
  }, [dispatch]);

  const handleOnError: onErrorHandler = useCallback(async (error) => {
    SentryCaptureError(error);
    openGlobalSnackbar('Failed to load!', SNACKBARTYPE.ERROR);
    console.error(error);
  }, []);

  const initializeCore = useCallback(async () => {
    try {
      setLoadedResId(resId);
      const coreInstance = await AdyenPlatformExperience({
        locale: 'en-US',
        onSessionCreate: handleOnSessionCreate,
        environment:
          Environment.env === ENVIRONMENTS.PRODUCTION ? 'live' : 'test',
        onError: handleOnError,
      });
      setCore(coreInstance);
    } catch (error) {
      SentryCaptureError(error);
      openGlobalSnackbar('Failed to initialize!', SNACKBARTYPE.ERROR);
      console.error(error);
    }
  }, [handleOnError, handleOnSessionCreate, resId]);

  useEffect(() => {
    if (resId !== loadedResId) {
      console.log('initializeCore');

      initializeCore();
    }
  }, [initializeCore, loadedResId, resId]);

  useEffect(() => {
    if (core) {
      const component = new Component({
        core,
        hideTitle: true,
      });
      component.mount(`#${id}`);

      return () => {
        component.unmount();
      };
    }
  }, [Component, core, id]);

  return core;
};
