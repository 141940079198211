import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ReactNode } from 'react';

import { Colors } from '../theme/colors';
import AppButton from './AppButton';

interface AppEmptyTableProps {
  title?: string;
  icon?: ReactNode;
  primaryButton?: {
    title: string;
    startIcon?: ReactNode;
    endIcon?: ReactNode;
    onClick: () => void;
  };
  secondaryButton?: {
    title: string;
    startIcon?: ReactNode;
    endIcon?: ReactNode;
    onClick: () => void;
  };
}

function AppEmptyTable({
  title,
  icon,
  primaryButton,
  secondaryButton,
}: Readonly<AppEmptyTableProps>) {
  return (
    <>
      <Box
        sx={{
          background: Colors.primaryLight,
          borderRadius: '10px',
          width: '40px',
          aspectRatio: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        {icon ?? (
          <SearchIcon
            sx={{ color: Colors.primary }}
            data-testid="default-icon"
          />
        )}
      </Box>

      <Typography variant="body2" sx={{ mt: 2, fontWeight: 500 }}>
        {title ?? 'No Results Found'}
      </Typography>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          mt: 2,
        }}>
        {primaryButton ? (
          <AppButton
            color="primary"
            variant="contained"
            title={primaryButton.title}
            startIcon={primaryButton.startIcon}
            endIcon={primaryButton.endIcon}
            onClick={primaryButton.onClick}
          />
        ) : null}
        {secondaryButton ? (
          <AppButton
            color="primary"
            variant="outlined"
            title={secondaryButton.title}
            startIcon={secondaryButton.startIcon}
            endIcon={secondaryButton.endIcon}
            onClick={secondaryButton.onClick}
            sx={{
              ml: 2,
            }}
          />
        ) : null}
      </Box>
    </>
  );
}

export default AppEmptyTable;
