import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import RefreshIcon from '@mui/icons-material/Refresh';
import { ADYEN_LEGAL_ENTITY_STATUS } from '../../../constants';
import { Colors } from '../../../theme/colors';
import { useAppSelector, useAppDispatch } from '../../../hooks';
import { selectAuth } from '../../../redux/selectors/authSelectors';
import OverviewOnboardingLink from './OverviewOnboardingLink';
import { getRestaurant } from '../../../features/auth/redux/authSlice';
import { useState } from 'react';
import { CircularProgress } from '@mui/material';
import AppButton from '../../../components/AppButton';

const OverviewStatusMessage = () => {
  const dispatch = useAppDispatch();
  const { restaurant } = useAppSelector(selectAuth);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const status = restaurant?.adyenAccount?.status;

  const handleRefresh = async () => {
    setIsRefreshing(true);
    try {
      await dispatch(getRestaurant());
    } finally {
      setIsRefreshing(false);
    }
  };

  if (status === ADYEN_LEGAL_ENTITY_STATUS.REVIEW_PENDING) {
    return (
      <>
        <Box sx={{ textAlign: 'center' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              mb: 1,
            }}>
            <Typography variant="h4">Your account is under review</Typography>
          </Box>
          <Typography
            variant="body1"
            sx={{
              color: Colors.fontColorSecondary,
              mb: 3,
            }}>
            We're currently reviewing your account information. This usually
            takes 1-2 business days
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 1,
            }}>
            <Typography
              variant="body1"
              sx={{ color: Colors.fontColorSecondary, mb: 1 }}>
              Review in progress
            </Typography>
            <AppButton
              variant="contained"
              endIcon={
                isRefreshing ? (
                  <CircularProgress
                    size="20px"
                    thickness={5}
                    sx={{
                      color: Colors.fontColorInvert,
                    }}
                  />
                ) : (
                  <RefreshIcon />
                )
              }
              disabled={isRefreshing}
              onClick={handleRefresh}>
              Click to refresh
            </AppButton>
          </Box>
        </Box>
      </>
    );
  }

  // Default message for PENDING status
  return (
    <>
      <Box sx={{ textAlign: 'center', mb: 4 }}>
        <Typography variant="h4" sx={{ mb: 1 }}>
          You're just a few steps away from completing your account!
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: Colors.fontColorSecondary,
          }}>
          Complete your account setup to start accepting payments.
        </Typography>
      </Box>
      <OverviewOnboardingLink />
    </>
  );
};

export default OverviewStatusMessage;
