import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { Colors } from '../../../theme/colors';
import AppButton from '../../AppButton';

export interface PaginationProps {
  start?: number;
  end?: number;
  rows: unknown[] | undefined;
  rowCount: number | undefined;
  handlePreviousClick?: () => void;
  disablePrevious: boolean | undefined;
  handleNextClick?: () => void;
  disableNext: boolean | undefined;
  disablePageIndicator?: boolean;
  enableShowAllResultsButton?: boolean;
  handleShowAllResultsClick?: () => void;
  showAllResultsStatus?: boolean;
}

const Pagination = ({
  start,
  end,
  rowCount,
  rows,
  handlePreviousClick,
  disablePrevious,
  handleNextClick,
  disableNext,
  disablePageIndicator,
  enableShowAllResultsButton,
  handleShowAllResultsClick,
  showAllResultsStatus,
}: PaginationProps) => {
  const calculateResultEnd = () => {
    if (!rows || rows.length < 10) {
      return start !== undefined && rows?.length !== undefined
        ? start + (rows?.length || 0) - 1
        : start;
    }
    return end;
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: disablePageIndicator ? 'flex-end' : 'space-between',
        my: '11px',
      }}>
      {disablePageIndicator ? null : (
        <Typography
          sx={{
            display: 'flex',
            alignItems: 'center',
            fontSize: '14px',
            fontWeight: 500,
            marginLeft: '10px',
            textTransform: 'none',
            color: Colors.fontColorSecondary,
          }}>
          Viewing {showAllResultsStatus ? 1 : start} -{' '}
          {showAllResultsStatus ? rowCount : calculateResultEnd()} of {rowCount}{' '}
          results
        </Typography>
      )}
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {enableShowAllResultsButton && (
          <AppButton
            onClick={handleShowAllResultsClick}
            title={showAllResultsStatus ? 'View Less' : 'View All'}
            variant="outlined"
            size="small"
            color="secondary"
            sx={{ ml: 1 }}
          />
        )}
        <AppButton
          onClick={handlePreviousClick}
          disabled={disablePrevious}
          title="Previous"
          variant="outlined"
          size="small"
          color="secondary"
          sx={{ ml: 1 }}
        />
        <AppButton
          onClick={handleNextClick}
          disabled={disableNext}
          title="Next"
          variant="outlined"
          size="small"
          color="secondary"
          sx={{ ml: 1 }}
        />
      </Box>
    </Box>
  );
};

export default Pagination;
